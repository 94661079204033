
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import UpdateStaffAndAdminUser from "@/components/admin/UpdateStaffAndAdminUser.vue";

export default defineComponent({
  name: "admin-add-new-administrator",
  components: {
    UpdateStaffAndAdminUser,
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Edit", ["Users", "Administrators"]);
    });
  },
  created() {
    //Set page title
    document.title = "Edit Administrator | " + process.env.VUE_APP_TITLE;
  },
});
